import React from 'react';

import FieldWork from './views/FieldWork/FieldWork';
import FieldConstruction from './views/FieldWork/Construction';
import FieldCapture from './views/FieldWork/Capture';
import FieldCapture2 from './views/FieldWork/Capture2';
import FieldConstructionPhotos from './views/FieldWork/Photos';
import FieldAttachments from './views/FieldWork/Attachments';

const Breadcrumbs = React.lazy(() => import('./views/Base/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/Base/Cards'));
const Carousels = React.lazy(() => import('./views/Base/Carousels'));
const Collapses = React.lazy(() => import('./views/Base/Collapses'));
const Dropdowns = React.lazy(() => import('./views/Base/Dropdowns'));
const Forms = React.lazy(() => import('./views/Base/Forms'));
const Jumbotrons = React.lazy(() => import('./views/Base/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/Base/ListGroups'));
const Navbars = React.lazy(() => import('./views/Base/Navbars'));
const Navs = React.lazy(() => import('./views/Base/Navs'));
const Paginations = React.lazy(() => import('./views/Base/Paginations'));
const Popovers = React.lazy(() => import('./views/Base/Popovers'));
const ProgressBar = React.lazy(() => import('./views/Base/ProgressBar'));
const Switches = React.lazy(() => import('./views/Base/Switches'));
const Tables = React.lazy(() => import('./views/Base/Tables'));
const Tabs = React.lazy(() => import('./views/Base/Tabs'));
const Tooltips = React.lazy(() => import('./views/Base/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/Buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/Buttons/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/Buttons/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/Buttons/Buttons'));
const Charts = React.lazy(() => import('./views/Charts'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const CoreUIIcons = React.lazy(() => import('./views/Icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/Icons/Flags'));
const FontAwesome = React.lazy(() => import('./views/Icons/FontAwesome'));
const SimpleLineIcons = React.lazy(() => import('./views/Icons/SimpleLineIcons'));
const Alerts = React.lazy(() => import('./views/Notifications/Alerts'));
const Badges = React.lazy(() => import('./views/Notifications/Badges'));
const Modals = React.lazy(() => import('./views/Notifications/Modals'));
const Colors = React.lazy(() => import('./views/Theme/Colors'));
const Typography = React.lazy(() => import('./views/Theme/Typography'));
const Widgets = React.lazy(() => import('./views/Widgets/Widgets'));
const Users = React.lazy(() => import('./views/Users/View'));
const User = React.lazy(() => import('./views/Users/User'));
const EditUser = React.lazy(() => import('./views/Users/EditUser'));
const Logs = React.lazy(() => import('./views/Logging/Logs'));
const Projects = React.lazy(() => import('./views/Projects/Projects'));
const Project = React.lazy(() => import('./views/Projects/Project'));
const Tasks = React.lazy(() => import('./views/Tasks/Tasks'));
const Task = React.lazy(() => import('./views/Tasks/Task'));
const FinanceView = React.lazy(() => import('./views/Finance/FinanceView'));
const ItemCodes = React.lazy(() => import('./views/ItemCodes/ItemCodes'));
const Lookups = React.lazy(() => import('./views/Lookups/Lookups'));
const Groups = React.lazy(() => import('./views/Groups/Groups'));
const Group = React.lazy(() => import('./views/Groups/Group'));
const EditGroup = React.lazy(() => import('./views/Groups/EditGroup'));
const IndependentContractors = React.lazy(() => import('./views/IndependentContractors/IndependentContractors'));
const Constructions = React.lazy(() => import('./views/Construction/Search'));
const ConstructionItemCodes = React.lazy(() => import('./views/Construction/ItemCodes'));
const SubcontractorCodes = React.lazy(() => import('./views/Construction/ItemChildCodes'));
const ConstructionEdit = React.lazy(() => import('./views/Construction/Edit'));
const ConstructionView = React.lazy(() => import('./views/Construction/View'));
const ConstructionBulkImport = React.lazy(() => import('./views/Construction/BulkImport'));
const Reports = React.lazy(() => import('./views/Reports/Reports'));
const ConstructionDailyRun = React.lazy(() => import('./views/Reports/ConstructionDailyRun'));
const ConstructionClaims = React.lazy(() => import('./views/Reports/ConstructionClaims'));
const ConstructionInvoicing = React.lazy(() => import('./views/Reports/ConstructionInvoicing'));
const ConstructionPayment = React.lazy(() => import('./views/Reports/ConstructionPayment'));
const SchedulingTool = React.lazy(() => import('./views/SchedulingTool/SchedulingTool'));
const SchedulingToolOld = React.lazy(() => import('./views/SchedulingTool/SchedulingToolOld'));
const ScheduleOverview = React.lazy(() => import('./views/SchedulingTool/ScheduleOverview'));
const TicketOfWork = React.lazy(() => import('./views/FieldWork/TicketOfWork'));
const SystemSettings = React.lazy(() => import('./views/SystemSettings/View'));
const DashboardSchedule = React.lazy(() => import('./views/Dashboard/Schedule'));
const BuilderApprovals = React.lazy(() => import('./views/BuilderApprovals/BuilderApprovals'));
const PotentialJobsView = React.lazy(() => import('./views/PotentialJobs/PotentialJobsView'));
const AdminTasks = React.lazy(() => import('./views/AdminTasks/AdminTasks'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard, claims: ['CAN_VIEW_DASHBOARD'] },
  {
    path: '/dashboard/schedule',
    exact: true,
    name: 'Schedule',
    component: DashboardSchedule,
    layout: 'NoChrome',
    claims: ['CAN_VIEW_DASHBOARD']
  },
  {
    path: '/dashboard/schedule/:groupid',
    exact: true,
    name: 'Schedule',
    component: DashboardSchedule,
    layout: 'NoChrome',
    claims: ['CAN_VIEW_DASHBOARD']
  },
  { path: '/field/:tab', exact: true, name: 'Field Work', component: FieldWork, claims: ['CAN_ACCESS_FIELD_WORK'] },
  { path: '/field/cards', name: 'Field Work', component: FieldWork, claims: ['CAN_ACCESS_FIELD_WORK'] },

  {
    path: '/field/construction/:constructionId/:taskId',
    exact: true,
    name: 'Construction',
    component: FieldConstruction,
    claims: ['CAN_ACCESS_FIELD_WORK']
  },
  {
    path: '/field/construction/:id/capture/:taskId',
    exact: true,
    name: 'Capture',
    component: FieldCapture,
    claims: ['CAN_ACCESS_FIELD_WORK'],
    layout: 'NoChrome'
  },
  {
    path: '/field/construction/:id/capture2/:taskId',
    exact: true,
    name: 'Capture2',
    component: FieldCapture2,
    claims: ['CAN_ACCESS_FIELD_WORK'],
    layout: 'NoChrome'
  },
  {
    path: '/field/construction/:id/:taskId/attachments',
    exact: true,
    name: 'Attachments',
    component: FieldAttachments,
    claims: ['CAN_ACCESS_FIELD_WORK']
  },
  {
    path: '/field/construction/:id/photos',
    exact: true,
    name: 'Photos',
    component: FieldConstructionPhotos,
    claims: ['CAN_ACCESS_FIELD_WORK'],
    layout: 'NoChrome'
  },
  {
    path: '/field/task/:constructionId/:taskId/ticket-of-work',
    exact: true,
    name: 'Ticket of Work',
    component: TicketOfWork,
    claims: ['CAN_ACCESS_TICKET_OF_WORK'],
    layout: 'NoChrome'
  },
  { path: '/theme', exact: true, name: 'Theme', component: Colors, claims: ['CAN_VIEW_STYLES'] },
  { path: '/theme/colors', name: 'Colors', component: Colors, claims: ['CAN_VIEW_STYLES'] },
  { path: '/theme/typography', name: 'Typography', component: Typography, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base', exact: true, name: 'Base', component: Cards, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/cards', name: 'Cards', component: Cards, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/forms', name: 'Forms', component: Forms, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/switches', name: 'Switches', component: Switches, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/tables', name: 'Tables', component: Tables, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/tabs', name: 'Tabs', component: Tabs, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/carousels', name: 'Carousel', component: Carousels, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/collapses', name: 'Collapse', component: Collapses, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/navbars', name: 'Navbars', component: Navbars, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/navs', name: 'Navs', component: Navs, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/paginations', name: 'Paginations', component: Paginations, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/popovers', name: 'Popovers', component: Popovers, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar, claims: ['CAN_VIEW_STYLES'] },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips, claims: ['CAN_VIEW_STYLES'] },
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons, claims: ['CAN_VIEW_STYLES'] },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons, claims: ['CAN_VIEW_STYLES'] },
  {
    path: '/buttons/button-dropdowns',
    name: 'Button Dropdowns',
    component: ButtonDropdowns,
    claims: ['CAN_VIEW_STYLES']
  },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups, claims: ['CAN_VIEW_STYLES'] },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons, claims: ['CAN_VIEW_STYLES'] },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons, claims: ['CAN_VIEW_STYLES'] },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons, claims: ['CAN_VIEW_STYLES'] },
  { path: '/icons/flags', name: 'Flags', component: Flags, claims: ['CAN_VIEW_STYLES'] },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome, claims: ['CAN_VIEW_STYLES'] },
  {
    path: '/icons/simple-line-icons',
    name: 'Simple Line Icons',
    component: SimpleLineIcons,
    claims: ['CAN_VIEW_STYLES']
  },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts, claims: ['CAN_VIEW_STYLES'] },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts, claims: ['CAN_VIEW_STYLES'] },
  { path: '/notifications/badges', name: 'Badges', component: Badges, claims: ['CAN_VIEW_STYLES'] },
  { path: '/notifications/modals', name: 'Modals', component: Modals, claims: ['CAN_VIEW_STYLES'] },
  { path: '/widgets', name: 'Widgets', component: Widgets, claims: ['CAN_VIEW_STYLES'] },
  { path: '/charts', name: 'Charts', component: Charts, claims: ['CAN_VIEW_STYLES'] },
  {
    path: '/admin/manage-users/users',
    exact: true,
    name: 'Users',
    component: Users,
    claims: ['CAN_MANAGE_USERS', 'CAN_MANAGE_ROLE_CLAIMS']
  },
  {
    path: '/admin/manage-users/rolePermissions',
    exact: true,
    name: 'Users',
    component: Users,
    claims: ['CAN_MANAGE_ROLE_CLAIMS']
  },
  {
    path: '/admin/manage-users/users/:id',
    exact: true,
    name: 'User Details',
    component: User,
    claims: ['CAN_MANAGE_USERS']
  },
  { path: '/admin/manage-users/users/:id/edit', name: 'Edit User', component: EditUser, claims: ['CAN_MANAGE_USERS'] },

  // Not in use
  // { path: '/orders', exact: true, name: 'Orders', component: Orders, role: 'Administrator' },
  // { path: '/orders/:id', exact: true, name: 'Order Details', component: Order },
  // { path: '/orders/:id/edit', exact: true, name: 'Edit Order', component: EditOrder, role: 'Administrator' },

  { path: '/logs', exact: true, name: 'Logs', component: Logs, claims: ['CAN_VIEW_LOGS'] },
  { path: '/projects', exact: true, name: 'Projects', component: Projects },
  { path: '/project/:id', name: 'Project Details', component: Project },
  { path: '/tasks', exact: true, name: 'Tasks', component: Tasks },
  { path: '/tasks/:id', name: 'Task', component: Task },

  // Not in use
  // { path: '/emails', exact: true, name: 'Emails', component: Emails, role: 'Administrator' },
  // { path: '/emails/:id', exact: true, name: 'Email Details', component: Email, role: 'Administrator' },
  // { path: '/emails/:id/attachments', exact: true, name: 'Tag Attachments', component: EmailTagAttachments },

  { path: '/finance', exact: true, name: 'Finance', component: FinanceView, claims: ['CAN_ACCESS_FINANCE'] },
  { path: '/finance/:tab', exact: true, name: 'Finance', component: FinanceView, claims: ['CAN_ACCESS_FINANCE'] },

  { path: '/admin', exact: true, name: 'Admin', claims: ['CAN_ACCESS_ADMINISTRATION'] },
  {
    path: '/admin/itemcodes',
    exact: true,
    name: 'Pre-install Item Codes',
    component: ItemCodes,
    claims: ['CAN_MANAGE_ITEM_CODES']
  },
  { path: '/admin/groups', exact: true, name: 'Groups', component: Groups, claims: ['CAN_MANAGE_GROUPS'] },
  { path: '/admin/groups/:id', exact: true, name: 'Group Details', component: Group, claims: ['CAN_MANAGE_GROUPS'] },
  {
    path: '/admin/groups/:id/edit',
    exact: true,
    name: 'Edit Group',
    component: EditGroup,
    claims: ['CAN_MANAGE_GROUPS']
  },
  {
    path: '/admin/independent-contractors',
    exact: true,
    name: 'Independent Contractors',
    component: IndependentContractors,
    claims: ['CAN_MANAGE_INDEPENDENT_CONTRACTORS']
  },
  { path: '/admin/lookups', exact: true, name: 'Lookups', component: Lookups, claims: ['CAN_MANAGE_LOOKUPS'] },
  {
    path: '/admin/settings',
    exact: true,
    name: 'System Settings',
    component: SystemSettings,
    claims: ['CAN_MANAGE_SYSTEM_SETTINGS']
  },
  {
    path: '/admin/settings/:tab',
    exact: true,
    name: 'System Settings',
    component: SystemSettings,
    claims: ['CAN_MANAGE_SYSTEM_SETTINGS']
  },
  {
    path: '/admin/settings/jobSettings/:tab/:worktype',
    exact: true,
    name: 'jobSettings',
    component: SystemSettings,
    claims: ['CAN_MANAGE_SYSTEM_SETTINGS']
  },
  // temp, this is a way to reference the old data
  {
    path: '/admin/settings/jobSettings/:tab',
    exact: true,
    name: 'jobSettings',
    component: SystemSettings,
    claims: ['CAN_MANAGE_SYSTEM_SETTINGS']
  },
  {
    path: '/jobs',
    exact: true,
    name: 'Construction Search',
    component: Constructions,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  {
    path: '/potentialjobs',
    exact: true,
    name: 'Potential Jobs',
    component: PotentialJobsView,
    claims: ['CAN_ACCESS_POTENTIAL_JOBS']
  },
  {
    path: '/potentialjobs/:tab',
    exact: true,
    name: 'Potential Jobs',
    component: PotentialJobsView,
    claims: ['CAN_ACCESS_POTENTIAL_JOBS']
  },
  {
    path: '/scheduling-tool',
    exact: true,
    name: 'Scheduling Tool',
    component: SchedulingTool,
    claims: ['CAN_ACCESS_SCHEDULE']
  },
  {
    path: '/scheduling-tool/:date',
    exact: true,
    name: 'Scheduling Tool',
    component: SchedulingTool,
    claims: ['CAN_ACCESS_SCHEDULE']
  },
  {
    path: '/scheduling-tool-old',
    exact: true,
    name: 'Scheduling Tool Old',
    component: SchedulingToolOld,
    claims: ['CAN_ACCESS_OLD_SCHEDULE']
  },
  {
    path: '/scheduling-tool-old/:date',
    exact: true,
    name: 'Scheduling Tool Old',
    component: SchedulingToolOld,
    claims: ['CAN_ACCESS_OLD_SCHEDULE']
  },
  {
    path: '/schedule-overview',
    exact: true,
    name: 'Schedule Overview',
    component: ScheduleOverview,
    claims: ['CAN_ACCESS_SCHEDULE']
  },
  {
    path: '/admin/constructioncodes',
    exact: true,
    name: 'Construction Item Codes',
    component: ConstructionItemCodes,
    claims: ['CAN_MANAGE_CONSTRUCTION_CODES']
  },
  {
    path: '/admin/constructioncodes/:id',
    exact: true,
    name: 'Construction Item Codes',
    component: ConstructionItemCodes,
    claims: ['CAN_MANAGE_CONSTRUCTION_CODES']
  },
  {
    path: '/admin/childcodes',
    exact: true,
    name: 'Subcontractor Codes',
    component: SubcontractorCodes,
    claims: ['CAN_MANAGE_SUBCONTRACTOR_CODES']
  },
  {
    path: '/admin/childcodes/:id',
    exact: true,
    name: 'Subcontractor Codes',
    component: SubcontractorCodes,
    claims: ['CAN_MANAGE_SUBCONTRACTOR_CODES']
  },
  {
    path: '/builderapprovals',
    exact: true,
    name: 'Builder Approvals',
    component: BuilderApprovals,
    claims: ['CAN_ACCESS_BUILDER_APPROVALS']
  },
  {
    path: '/builderapprovals/:tab',
    exact: true,
    name: 'Builder Approvals',
    component: BuilderApprovals,
    claims: ['CAN_ACCESS_BUILDER_APPROVALS']
  },
  {
    path: '/admin/admintasks',
    exact: true,
    name: 'Admin Tasks',
    component: AdminTasks,
    claims: ['CAN_ACCESS_ADMINISTRATOR_TASKS']
  },
  {
    path: '/construction/:id/edit',
    exact: true,
    name: 'Construction Edit',
    component: ConstructionEdit,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  // { path: '/construction/:id/ticket-of-work', exact: true, name: 'Ticket of Work', component: TicketOfWork, role: 'Program: Construction', layout: 'NoChrome' },
  {
    path: '/construction/:id',
    exact: true,
    name: 'Construction View',
    component: ConstructionView,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  {
    path: '/construction/:id/:tab',
    exact: true,
    name: 'Construction View',
    component: ConstructionView,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  {
    path: '/construction-bulk-import',
    exact: true,
    name: 'Construction Bulk Import',
    component: ConstructionBulkImport,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  { path: '/reports', exact: true, name: 'Reports', component: Reports, claims: ['CAN_VIEW_REPORTS'] },
  {
    path: '/reports/construction-daily-run',
    exact: true,
    name: 'Construction: Daily Run',
    component: ConstructionDailyRun,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  {
    path: '/reports/construction-claims',
    exact: true,
    name: 'Construction: Claims',
    component: ConstructionClaims,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  {
    path: '/reports/construction-invoicing',
    exact: true,
    name: 'Construction: Invoicing',
    component: ConstructionInvoicing,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  },
  {
    path: '/reports/construction-payment',
    exact: true,
    name: 'Construction: Payment',
    component: ConstructionPayment,
    claim: ['CAN_ACCESS_CONSTRUCTION']
  }
];

export default routes;
