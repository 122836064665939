import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { FileUpload } from '../../components/Files/FileAttachments';
import moment from 'moment';
import 'moment-timezone';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

const GET_CONSTRUCTION = gql`
  query ConstructionQuery($constructionId: ID) {
    construction(id: $constructionId) {
      id
      status
      comments
      workOrder
      releaseDate
      projectFSAM
      description
      address
      contactDetails
      buildType
      workType
      premises
      deliveryPartner
      deliveryPartnerPm
      adtechPm
      scheduler
      originalDueDate
      updatedDueDate
      dbydExpiryDate
      startDueDate
      laseDate
      onsiteDate
      crqDate
      integrationDate
      acceleratedJob
      siteContact
      bomComments
      contract
      region
      developer
      quoteNumber
      prePaymentNumber
      checklist
      schedules {
        id
        name
        owner
        resource
        groupId
        issuedDate
        dueDate
        completedDate
        comments
        initial
        ignored
        isPayment
        onsiteDate
      }
      attachments(orderBy: { path: "createdUtc", descending: true }) {
        id
        title
        url
        sizeBytes
        tags
        createdUtc
        modifiedUtc
        comments
      }
    }
    users(claim: "IS_CONSTRUCTION_USER") {
      id
      email
      firstName
      lastName
    }
  }
`;

const REMOVE_ATTACHMENT = gql`
  mutation RemoveAttachment($id: ID!) {
    removeAttachment(id: $id) {
      id
      title
    }
  }
`;

const Attachments = (props) => {
  let { id, taskId } = props.match.params;

  const { data, refetch } = useQuery(GET_CONSTRUCTION, {
    fetchPolicy: 'no-cache',
    variables: { constructionId: id }
  });

  const [construction, setConstruction] = useState({});
  const [tags, setTags] = useState([]);

  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT);

  useEffect(() => {
    if (data) {
      setConstruction(data.construction);
      setTags([...data.construction.schedules.filter((s) => s.id == taskId).map((s) => s.name)]);
    }
  }, [data]);

  const [documents, setDocuments] = useState([]);

  const [filter, setFilter] = useState('');
  const [withoutCategory, setWithoutCategory] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    refetch();
  }, [documents]);

  const handleChange = (id, e) => {
    let newDocuments = documents.map((d) => {
      if (d.id === id) {
        d[e.target.name] = e.target.value;
      }
      return d;
    });
    setDocuments([...newDocuments]);
  };

  const removeItem = (document, id) => {
    removeAttachment({ variables: { id: document.id } })
      .then(() => {
        refetch();
      })
      .catch((err) => {});
  };

  const onFileUploaded = (attachment) => {
    var docTemp = [...documents];
    docTemp.unshift({
      id: attachment.attachment.id,
      tags: tags.join(';'),
      title: attachment.attachment.title,
      sizeBytes: attachment.attachment.sizeBytes,
      url: attachment.attachment.url
    });
    setDocuments(docTemp);
  };

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  return (
    <>
      {documents && (
        <>
          <div className="card">
            <div className="card-body">
              <div className="py-1">
                <Link className="btn btn- btn-secondary " to={'/field/construction/' + id + '/' + taskId}>
                  <i className="icon-arrow-left"></i> Back
                </Link>
              </div>
              <FileUpload
                onFileUploaded={onFileUploaded}
                constructionId={construction.id}
                tags={tags}
                isDocAttachment={true}
              />
            </div>
          </div>

          {withoutCategory && (
            <div className="alert alert-danger mt-3">
              There are documents without categories... Please choose a category and save.
            </div>
          )}

          {construction &&
            construction.attachments &&
            construction.attachments.map((doc, index) => (
              <div
                key={index + doc.title}
                className={
                  'card p-1 ' +
                  ((doc.tags && doc.tags.indexOf(filter) > -1) || filter === '' ? '' : 'd-none') +
                  (doc.tags === null || doc.tags === '' ? ' table-danger' : '')
                }
              >
                <div className="row">
                  <div className="col-md">
                    <a target="_blank" href={doc.url}>
                      {doc.title}
                    </a>
                  </div>
                  <div className="col-md">
                    {moment.utc(doc.createdUtc).tz(moment.tz.guess()).format('DD MMM YYYY hh:mm A')} -{' '}
                    {humanFileSize(doc.sizeBytes)}
                  </div>
                  <div className="col-md">
                    <Button close tabIndex="-1" onClick={() => removeItem(doc, doc.id)}>
                      <i className="icon-close"></i>
                    </Button>
                  </div>
                  <div className="col-md">{doc.tags}</div>
                </div>
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default Attachments;
