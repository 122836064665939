import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Sync from './Sync';
import ErrorBoundary from './ErrorBoundary';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import TaskHistory from './TaskHistory';
import FieldWorkList from './FieldWorkList';
import { useAuth } from '../../auth/AuthProvider';

const GET_FIELD_DATA = gql`
  query GetFieldData {
    addressTypeSetting: systemSettings(where: { path: "Name", comparison: equal, value: "Address Type" }) {
      id
      name
      workType
      config
    }
    customDisplayNames: systemSettings(
      where: { path: "Name", comparison: equal, value: "Optional Construction Fields" }
    ) {
      id
      name
      workType
      config
    }
    optionalFieldWorkColumns: systemSettings(where: { path: "Name", comparison: equal, value: "Field Work List" }) {
      id
      name
      workType
      config
    }
    fieldJobStatusSettings: systemSettings(where: { path: "Name", comparison: equal, value: "Field Job Status" }) {
      id
      name
      workType
      config
    }
    jobStatusSettings: systemSettings(where: { path: "Name", comparison: equal, value: "Job Status Settings" }) {
      id
      name
      workType
      config
    }
    lookups {
      name
      data
    }
    fieldGroups {
      id
      name
    }
    users {
      id
      displayName
      email
    }
  }
`;

const FieldWork = () => {
  const [settings, setSettings] = useState(null);
  const [structuredAddressWorkTypes, setStructuredAddressWorkTypes] = useState(null);
  const [fieldGroups, setFieldGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(localStorage.getItem('field-selectedGroup'));

  const auth = useAuth();

  const { tab } = useParams();

  const { data: settingsData, loading } = useQuery(GET_FIELD_DATA, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (!settingsData) return;

    setStructuredAddressWorkTypes(() => {
      return settingsData.addressTypeSetting
        .filter((setting) => {
          const config = JSON.parse(setting.config);
          return config?.addressSetting?.addressType === 'structured';
        })
        .map((setting) => setting.workType);
    });

    setFieldGroups(() => {
      return settingsData.fieldGroups;
    });

    setSettings({
      customDisplayNames: settingsData.customDisplayNames,
      optionalFieldWorkColumns: settingsData.optionalFieldWorkColumns,
      fieldJobStatusSettings: settingsData.fieldJobStatusSettings,
      jobStatusSettings: settingsData.jobStatusSettings,
      lookups: settingsData.lookups,
      users: settingsData.users
    });
  }, [settingsData]);

  useEffect(() => {
    localStorage.setItem('field-selectedGroup', selectedGroup);
  }, [selectedGroup]);

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <Sync></Sync>
      <div className="row mb-3">
        <div className="col-md">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <Link
                className={`nav-link ` + (tab === 'cards' || tab === 'construction' ? 'active' : '')}
                aria-current="page"
                to="/field/cards"
              >
                Tasks
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link ` + (tab === 'completed' ? 'active' : '')}
                aria-current="page"
                to="/field/completed"
              >
                Completed
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md text-md-end">
          <select
            className="form-control float-right"
            value={selectedGroup}
            style={{ width: 'auto' }}
            onChange={(e) => setSelectedGroup(e.target.value)}
          >
            <option value="">---ALL---</option>
            {auth.hasClaim('CAN_VIEW_ALL_GROUPS') && <option value="0">Admin (ALL)</option>}
            {fieldGroups?.map((g) => {
              return (
                <option key={g.id} value={g.id}>
                  {g.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {(tab === 'cards' || tab === 'construction') && settings && structuredAddressWorkTypes && (
        <FieldWorkList
          settings={settings}
          structuredAddressWorkTypes={structuredAddressWorkTypes}
          groupId={selectedGroup}
        />
      )}
      {tab === 'completed' && (
        <TaskHistory structuredAddressWorkTypes={structuredAddressWorkTypes} groupId={selectedGroup} />
      )}
    </>
  );
};

const WrappedFieldWork = (props) => {
  return (
    <ErrorBoundary>
      <FieldWork {...props} />
    </ErrorBoundary>
  );
};

// wrap Fieldwork in an error boundary
export default WrappedFieldWork;
